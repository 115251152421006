<template>
  <layout>
    <v-card>
        <v-card-title>
            <v-icon
                large
                left
            >
                mdi-road
            </v-icon>
            <span class="title font-weight-light">มอบหมายงาน</span>
        </v-card-title>
    </v-card>
    <Confirm ref="confirm"></Confirm>
    <v-card class="my-3 pb-10">
        <v-card-title>
            <span class="subtitle-1">รายละเอียด</span>
        </v-card-title>
        <v-divider class="mx-4 mb-5" />
        <ValidationObserver ref="form">
            <v-form
                ref="form"
                lazy-validation
            >
                <v-container class="body-2">
                    <v-row>
                        <v-col cols="2">
                            <v-subheader>เรื่อง</v-subheader>
                        </v-col>
                        <v-col cols="8">
                            <div class="pt-3">{{ topic }}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <v-subheader>วันที่แจ้งงาน</v-subheader>
                        </v-col>
                        <v-col cols="8">
                            <div class="pt-3">{{ jobDate }}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <v-subheader>ผู้แจ้งงาน</v-subheader>
                        </v-col>
                        <v-col cols="8">
                            <div class="pt-3">{{ staffFirstname }} {{ staffLastname }}</div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <v-subheader>ประเภทงาน</v-subheader>
                        </v-col>
                        <v-col cols="8">
                            <div class="pt-3">{{ jobTypeName }}</div>
                        </v-col>
                    </v-row>
                    <!-- <v-row>
                        <v-col cols="2">
                            <v-subheader>วันที่กำหนดเสร็จงาน</v-subheader>
                        </v-col>
                        <v-col cols="8">
                          <div class="pt-3">{{ dueDate }}</div>
                        </v-col>
                    </v-row> -->
                    <v-row>
                        <v-col cols="2">
                            <v-subheader>รายละเอียด</v-subheader>
                        </v-col>
                        <v-col cols="8">
                          <div class="pt-3" v-html="description"></div>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <v-subheader>แนบลิ้งค์ไฟล์เอกสาร</v-subheader>
                        </v-col>
                        <v-col cols="8">
                          <div class="pt-3"><a :href="linkFile" target="_blank">{{ linkFile }}</a></div>
                          <div class="pt-3"><a :href="linkFile2" target="_blank">{{ linkFile2 }}</a></div>
                          <div class="pt-3"><a :href="linkFile3" target="_blank">{{ linkFile3 }}</a></div>
                          <div class="pt-3"><a :href="linkFile4" target="_blank">{{ linkFile4 }}</a></div>
                          <div class="pt-3"><a :href="linkFile5" target="_blank">{{ linkFile5 }}</a></div>
                        </v-col>
                    </v-row>
                    <v-divider class="mx-4 mb-5" />
                    
                </v-container>
           

              <v-card-title>
                  <span class="subtitle-1">มอบหมาย</span>
              </v-card-title>
              <v-divider class="mx-4 mb-5" />
              
              <v-container>
                <v-checkbox
                  v-model="checkAllAssign"
                  label="เลือกมอบหมายทุกคน"
                ></v-checkbox>
                <div v-if="checkAllAssign">
                  <v-select
                    v-model="allJobType"
                    :items="jobTypeItems"
                    item-text="type_name"
                    item-value="id"
                    label="เลือกรายการ"
                    style="top: 12px"
                    dense
                    outlined
                  ></v-select>
                </div>
                <div v-if="checkAllAssign == false">
                  <v-card outlined>
                    <v-simple-table>
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th>
                                    มอบหมายให้
                                </th>
                                <th>
                                    เรื่อง
                                </th>
                                <th>
                                    ประเภทงาน
                                </th>
                                <th>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="(item, index) in assignData"
                                :key="index"
                            >
                                <td>
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="staff"
                                    rules="required"
                                  >
                                    <v-autocomplete
                                      v-model="item.staff"
                                      :items="staffItems"
                                      item-text="firstname"
                                      item-value="id"
                                      outlined
                                      dense
                                      style="top: 12px"
                                      :error-messages="errors"
                                      label="เลือกผู้รับผิดชอบ"
                                    ></v-autocomplete>
                                  </validation-provider>
                                </td>
                                <td>
                                  <v-text-field
                                      v-model.lazy="item.topic"
                                      style="top: 12px"
                                      dense
                                      outlined
                                  ></v-text-field>
                                </td>
                                <td>
                                  <validation-provider
                                    v-slot="{ errors }"
                                    name="jobType"
                                    rules="required"
                                  >
                                    <v-select
                                      v-model="item.job_type"
                                      :items="jobTypeItems"
                                      item-text="type_name"
                                      item-value="id"
                                      label="เลือกรายการ"
                                      :error-messages="errors"
                                      style="top: 12px"
                                      dense
                                      outlined
                                    ></v-select>
                                  </validation-provider>
                                </td>
                                <td>
                                  <v-btn
                                    icon
                                    color="red"
                                    @click="removeItem(index)"
                                  >
                                    <v-icon>mdi-close-box</v-icon>
                                  </v-btn>
                                </td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>
                  </v-card>
                  <div class="pt-2 mb-10">
                    <v-btn outlined color="primary" @click="addItem()">
                        เพิ่มผู้รับผิดชอบ
                    </v-btn>
                  </div>
                </div>
                <v-row>
                  <v-col>
                      <v-btn depressed color="success" @click="save()">
                          บันทึกมอบหมายงาน
                      </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
        </ValidationObserver>
    </v-card>
  </layout>
</template>
<script>
import Api from "@/api/Api";

export default {
    props: ['jobId'],
    data: () => ({
        storeUser: null,
        topic: '',
        dueDate: new Date().toISOString().substr(0, 10),
        description: '',
        linkFile: '',
        linkFile2: '',
        linkFile3: '',
        linkFile4: '',
        linkFile5: '',
        jobType: null,
        jobTypeItems: [],
        formChkErrors: false,
        menu: false,
        jobTypeName: '',
        jobDate: '',
        assignData: [{
          staff: null,
          topic: '',
          job_type: null
        }],
        staffItems: [],
        staffFirstname: '',
        staffLastname: '',
        requestBy: null,
        checkAllAssign: false,
        allJobType: null
    }),
    created() {
        this.storeUser = this.$store.getters.user
    },
    async mounted() {
      this.$store.commit('setLoading', true)
      await this.getJobType()
      await this.getStaff()
      await this.getData()
      this.$store.commit('setLoading', false)
    },
    methods: {
        addItem(){
          this.assignData.push({
            staff: null,
            topic: '',
            job_type: null
          })
        },
        removeItem(index){
          this.assignData.splice(index,1); 
        },
        async getJobType() {
            try {
                const res = (await Api().get("jobtype")).data;

                if (res.status == 'success') {
                    this.jobTypeItems = res.data
                } else {
                    console.log(res.data);
                }
            } catch (err) {
                console.log(err.message);
            }
        },
        async getStaff() {
            try {
                const res = (await Api().get("staff")).data;

                if (res.status == 'success') {
                    this.staffItems = res.data
                } else {
                    console.log(res.data);
                }
            } catch (err) {
                console.log(err.message);
            }
        },
        async getData() {
          try {
            const res = (await Api().get('jobrequest/' + this.jobId)).data;

            if (res.status == 'success') {
              this.topic = res.data.topic
              this.jobType = res.data.job_type
              this.dueDate = res.data.due_date
              this.description = res.data.description
              this.linkFile = res.data.link_file
              this.linkFile2 = res.data.link_file2
              this.linkFile3 = res.data.link_file3
              this.linkFile4 = res.data.link_file4
              this.linkFile5 = res.data.link_file5
              this.jobTypeName = res.data.type_name
              this.jobDate = res.data.job_date
              this.staffFirstname = res.data.firstname
              this.staffLastname = res.data.lastname
              this.requestBy = res.data.request_by
            } else {
              console.log(res.data);
            }

          } catch (err) {
            console.log(err.message);
          }
        },
        async save() {
            this.$store.commit('setLoading', true)
            const req = {
                job_parent: this.jobId,
                level: 2,
                due_date: this.dueDate,
                request_by: this.requestBy,
                assignLists: this.assignData,
                topic: this.topic,
                allJobType: this.allJobType,
                checkAllAssign: this.checkAllAssign
            }

            this.formChkErrors = false
            this.formChkErrors = await this.$refs.form.validate()

            if(this.formChkErrors){
              let res = (await Api().post("jobassign", req)).data;

              if (res.status == 'success') {
                  this.$store.commit('setLoading', false)
                  await this.$refs.confirm.open('ข้อความ', 'บันทึกข้อมูลเรียบร้อย', { color: 'primary', noconfirm: true })
                  this.$router.push('/request/all')
              }else{
                  this.$store.commit('setLoading', false)
              }
            }else{
                this.$store.commit('setLoading', false)
            }
        }
    }
};
</script>